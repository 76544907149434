<template>
<div :class="'pray dirr ' + $store.state.direction">
    <div class="prayer_timesCON">
        <div class="s_wrap them_overlay">
            <div class="container">
                <div class="s_row">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <ul class=" nav-tabs iitem">
                                <li>
                                    <a class="left_bottom hvr-ripple-out">
                                        <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/fajer.svg#fajer"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_fajir") }}</h6>
                                        <h6 class="title- tl">{{prayer.fajer}}</h6>
                                    </a></li>
                                <li>
                                    <a class="left_center hvr-ripple-out">
                                       <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/shoroq.svg#shoroq"></use></svg>
                                        </div>

                                        <h6 class="title- tl">{{ $t("index_sunrise") }}</h6>
                                        <h6 class="title- tl">{{prayer.rise}}</h6>
                                    </a>
                                </li>
                                <li><a class="center hvr-ripple-out">
                                   <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_doher") }}</h6>
                                        <h6 class="title- tl">{{prayer.noon}}</h6>
                                    </a></li>
                                <li><a class="right_center hvr-ripple-out">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/ghorp.svg#ghorp"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_maghrib") }}</h6>
                                        <h6 class="title- tl">{{prayer.ghrob}}</h6>
                                    </a></li>
                                <li><a class="right_bottom hvr-ripple-out">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("midnight") }}</h6>
                                        <h6 class="title- tl">{{prayer.mid}}</h6>
                                    </a></li>
                            </ul>
                        </div>
                        <div class="panel-body">
                            <div class="tab-content">

                                <div class="tab-pane">
                                    <div class="iitem-text">
                                        <div class="hands">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/hands-praying.svg#hands-praying"></use></svg>
                                        </div>
                                        <h4 class="titl-2 tl">{{ $t("prayer_times") }}</h4>
                                        <p>{{ $t("prayers_in_karbala") }}</p>
                                        <p class="messiri_fonted prayer_date" >
                                            <span class="hidden-sm-and-down" v-text="dateD+' - '"></span>
                                            <!-- <span v-html="dateH"></span> -->
                                            <span class="hidden-sm-and-down" v-html="dateH"></span> 
                                            <span class="hidden-md-and-up" v-text="dateH_ico"></span>
                                            <span class="hidden-sm-and-down" style="padding-right:5px" v-text="dateM"></span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="allcontent">
                    <div class=" heade">
                        <h4 class="titl-2 mb-2 tl">{{ $t("prayer_times") }}</h4>
                        <p class="text-dark">{{ $t("prayers_in_karbala") }}</p>
                        <p class="messiri_fonted text-dark" >
                            <span class="hidden-sm-and-down" v-text="dateD+' -'"></span>
                            <!-- <span v-html="dateH"></span> -->
                            <span class="hidden-sm-and-down" v-html="dateH"></span> 
                            <span class="hidden-md-and-up" v-text="dateH_ico"></span>
                            <span class="hidden-sm-and-down" v-text="dateM"> </span>
                        </p>
                    </div>

                    <div>
                        <ul class="ul-item">
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/fajer.svg#fajer"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_fajir") }}</h6>
                                        <h6 class="title- tl">{{prayer.fajer}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/shoroq.svg#shoroq"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("index_sunrise") }}</h6>
                                    <h6 class="title- tl">{{prayer.rise}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("index_doher") }}</h6>
                                    <h6 class="title- tl">{{prayer.noon}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/ghorp.svg#ghorp"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("index_maghrib") }}</h6>
                                    <h6 class="title- tl">{{prayer.ghrob}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui block3 mb-3">
                                   <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("midnight") }}</h6>
                                    <h6 class="title- tl">{{prayer.mid}}</h6>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>

let endpoints = [
    'https://alkafeel.net/main_bg/data/date_main.json?t='+ Date.now(),
    'salaDate'
]
// import Axios from "axios";
export default {
    name: "prayerTimes",
    data() {
        return {
            dateD: '',
            dateH: '',
            dateM: '',
            dateH_ico: '',
            prayer: {"fajer": "00:00","rise": "00:00","noon": "00:00","ghrob": "00:00","mid": "00:00"},
            date: [],
        }
    },
    watch: {
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    mounted() {
    },
    created () {
        this.ini ();
        // this.loadDate ();
        // this.getTimes ();
    },
    methods:{
        // getTimes () {
        //     Axios.get("salaDate", {})
        //     .then(res => {
        //         this.times = res.data;
        //         //console.log(res.data);
        //     })
        //     .catch(() => {
        //     });
        // },
        ini () {
            this.$axios
                .all(endpoints.map((endpoint) => this.$axios.get(endpoint)))
                .then(
                    this.$axios.spread((...responses) => {
                    const responseDate  = responses[0].data;
                    const responsePraym = responses[1].data;

                    if (typeof(responsePraym[0]) !== 'undefined') {this.prayer = responsePraym[0]}
                    this.date = responseDate.langs;
                    
                    try {
                        this.dateD = this.date[this.$i18n.locale].D;
                        this.dateH = this.date[this.$i18n.locale].H !== '' ? this.date[this.$i18n.locale].H+' <img src="assets/img/z-ali.svg"> ' : '';
                        this.dateH_ico = this.date[this.$i18n.locale].H;
                        this.dateM = this.date[this.$i18n.locale].M;
                    } catch (e) {

                    }
                    
                    })
                    
                )
        },
        
        loadDate () {
            try {
                this.dateD = this.date[this.$i18n.locale].D;
                this.dateH = this.date[this.$i18n.locale].H !== '' ? this.date[this.$i18n.locale].H+' <img src="assets/img/z-ali.svg"> ' : '';
                this.dateH_ico = this.date[this.$i18n.locale].H;
                this.dateM = this.date[this.$i18n.locale].M;
            } catch (e) {

            }
        },
    },
}
</script>

<style scoped>
    .prayer_date * {
        font-size: 16px!important;
    }
</style>